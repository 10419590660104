<template>
    <div class="myCard">
        <div class="cardPut">
            <input type="text" placeholder="输入简称或卡号查询" v-model="StorageQueryKey">
            <van-icon name="cross" @click="StorageQueryKey = ''" color="rgba(56, 56, 56, 1)" v-if="StorageQueryKey" />
            <img src="../../assets/image/myCards/serach.png" alt="" @click="queryKeyChange">
        </div>
        <div class="notCard" v-if="cardList?.length === 0">
            <img src="../../assets/image/myCards/Card.png" alt="">
            {{ queryKey ? '暂未查询到卡片，请重新查询': '暂未绑定卡片，现在去绑定卡片吧' }}
        </div>
        <template v-else>
            <div class="label">已绑定卡片 <span>共{{total}}张</span></div>
            <div class="CardItem" v-for="(item, index) in cardList" :key="item.id">
                <div class="cardHeader">{{ item.name }} <img src="../../assets/image/myCards/EditName.png" alt="" @click="onShow(item.name,item.id, index)"> {{ item.statusDesc }}</div>
                <div class="cardContent">
                    <img src="../../assets/image/myCards/Card.png" alt="">
                    <div class="cardInfo">
                        <div>卡号：{{ item.iccid }}</div>
                        <div>已用流量：{{ item.usedFlow >= 1024 ? (parseInt(item.usedFlow / 1024) + 'GB') : (item.usedFlow + 'MB') }}</div>
                    </div>
                    <span @click="goPath('/query?iccid=' + item.iccid)">去充值</span>
                </div>
                <div class="cardBottom">
                    套餐：{{ item.usePackageName }} {{ PackageStatusMap[item.usePackageStatus] }}
                    <span @click="remove(item.cardId)">删除</span>
                </div>
            </div>
        </template>
        <van-loading id="loading" v-if="total > cardList?.length" />
        <div class="addCard" @click="goPath('/addCard')">添加卡片</div>
        <van-dialog v-model:show="show" @cancel="onClose" title="卡片简称" show-cancel-button @confirm="editMineCardName">
            <div class="dialogContent">
                <input type="text" class="cardNamePut" v-model="cardName" maxlength="15">
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { getCurrentInstance, defineComponent, reactive, toRefs, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { Dialog } from 'vant'
import { setStore, getStore } from '@/utils/store'
export default defineComponent({
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  setup () {
    const { proxy: ctx } = getCurrentInstance()
    const { $router: router, $http: http, $tool: tool } = ctx
    const route = useRoute()
    const state = reactive({
      queryKey: '',
      StorageQueryKey: '',
      page: 0,
      show: false,
      openId: '',
      cardList: [],
      total: 1,
      cardName: '',
      cardId: '',
      EditIndex: -1,
      PackageStatusMap: {
        0: '待生效',
        1: '生效中',
        2: '已失效',
        3: '已过期',
        4: '已退款'
      }
    })
    const goPath = (path) => {
      window.location.href = window.location.origin + path
    //   router.push({
    //     path
    //   })
    }
    const queryKeyChange = () => {
      state.cardList = []
      state.queryKey = state.StorageQueryKey
      state.page = 1
      getCards(1)
    }
    const getUserInfo = (event) => {
    }
    const editMineCardName = () => {
      http.post('/card/mine/editMineCardName', {
        openid: state.openId,
        id: state.cardId,
        name: state.cardName
      }).then(res => {
        if (res.data.code !== 0) {
          tool.toast({
            msg: res.data.msg,
            duration: 1500
          })
        } else {
          tool.toast({
            msg: '修改成功',
            duration: 1500
          })
          state.cardList[state.EditIndex].name = state.cardName
        }
        console.log(res)
      })
    }
    const remove = (cardId) => {
      Dialog.confirm({
        title: '删除提醒',
        message: '此操作将从你的卡片列表中删除，确认删除吗？'
      }).then(() => {
        http.post('/card/mine/remove', {
          openId: state.openId,
          cardId
        }).then(res => {
          if (res.data.code !== 0) {
            tool.toast({
              msg: res.data.msg,
              duration: 1500
            })
          } else {
            tool.toast({
              msg: '删除成功',
              duration: 1500
            })
            state.cardList = []
            state.page = 1
            getCards(1)
          }
          console.log(res)
        })
      })
    }
    const getCards = (page) => {
      http.get('/card/mine/list', {
        params: {
          openId: state.openId,
          queryKey: state.queryKey,
          page,
          limit: 10
        }
      }).then(res => {
        if (res.data.code === 0) {
          state.page = page
          state.cardList = state.cardList.concat(res.data.data)
          state.total = res.data.count
        }
        console.log(res)
      })
    }
    const onClose = () => {
      state.show = false
    }
    const onShow = (name, id, index) => {
      state.cardName = name
      state.cardId = id
      state.EditIndex = index
      state.show = true
    }
    onMounted(() => {
      if (getStore({ name: 'userOpenId' })?.openId) {
        state.openId = getStore({ name: 'userOpenId' }).openId
        // const loadMore = new IntersectionObserver((entries) => { entries.forEach((entry) => { if (entry.isIntersecting) { getCards(state.page++) } }) })
        const loadMore = new IntersectionObserver((entries) => {
          entries.forEach((entry) => { 
            if (entry.isIntersecting) {
              console.log('卡片列表需要加载更多数据')  // 执行需要加载更多数据的操作，如获取下一页的卡片数据
              state.page += 1
              console.log({pages: state.page})
              getCards(state.page) 
            }
          })
        })
        const loading = document.querySelector('#loading')
        loadMore.observe(loading)
        return
      }
      if (route.query.code) {
        return http.post('/pay/getOpenId', {
          code: route.query.code,
          appId: 'wxcb4cb7a7be86647a'
          // appId: 'wxd750994715c3d4d9'
        }).then(({ data }) => {
          if (data.code === 0 && data.openId) {
            state.openId = data.openId
            // state.openId = 'oCUb05hdpcOKd_K2XH-FRnjbMqzM'
            setStore({
              name: 'userOpenId',
              content: {
                openId: state.openId
                // openId: 'oCUb05hdpcOKd_K2XH-FRnjbMqzM'
              },
              type: 'session'
            })
            // const loadMore = new IntersectionObserver((entries) => { entries.forEach((entry) => { if (entry.isIntersecting) { getCards(state.page++) } }) })
            const loadMore = new IntersectionObserver((entries) => {
              entries.forEach((entry) => { 
                if (entry.isIntersecting) {
                  console.log('卡片列表需要加载更多数据')  // 执行需要加载更多数据的操作，如获取下一页的卡片数据
                  state.page += 1
                  console.log({pages: state.page})
                  getCards(state.page) 
                }
              })
            })
            const loading = document.querySelector('#loading')
            loadMore.observe(loading)
          }
        })
      }
    })
    return {
      ...toRefs(state),
      goPath,
      getUserInfo,
      onClose,
      onShow,
      queryKeyChange,
      editMineCardName,
      remove
    }
  }
})
</script>

<style lang="scss" scoped>
.myCard {
    min-height: 100vh;
    min-width: 100vw;
    background: rgba(229, 229, 229, 0.33);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .cardPut {
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(229, 229, 229, 1);
        height: 80px;
        margin: 34px 20px 0;
        display: flex;
        align-items: center;
        padding: 0 20px;

        input {
            flex: 1;
            border: none;
            outline: none;

            &::placeholder {
                font-size: 24px;
                color: rgba(128, 128, 128, 1);
            }
        }
        img{
            margin-left: 5px;
        }
    }

    .notCard {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 24.32px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 35.23px;
        color: rgba(0, 0, 0, 1);
        margin: auto;
    }

    .label {
        padding: 0 52px;
        font-size: 26px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 38.15px;
        color: rgba(0, 0, 0, 1);
        margin-top: 42px;

        span {
            color: rgba(166, 166, 166, 1);
            margin-left: 3px;
        }
    }

    .CardItem {
        background: rgba(255, 255, 255, 1);
        border-radius: 6px;
        margin: 24px 24px 0;

        .cardHeader {
            height: 60px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            line-height: 1;
            font-size: 24.32px;
            font-weight: 400;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 1);

            img {
                margin-left: 12px;
                margin-right: auto;
            }
        }

        .cardContent {
            height: 120px;
            border: 2px solid rgba(229, 229, 229, 1);
            border-left: none;
            border-right: none;
            padding: 0 24px;
            display: flex;
            align-items: center;

            img {
                width: 46.62px;
                height: 46.62px;
            }

            .cardInfo {
                margin-left: 28px;
                font-size: 24.32px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 35.23px;
                color: rgba(0, 0, 0, 1);
                display: flex;
                flex-direction: column;
                gap: 14px;
            }

            >span {
                font-size: 28.38px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 41.09px;
                color: rgba(42, 130, 228, 1);
                margin-left: auto;
            }
        }

        .cardBottom {
            height: 60px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            line-height: 1;
            font-size: 24.32px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 35.23px;
            color: rgba(0, 0, 0, 1);

            span {
                font-size: 24.32px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 35.23px;
                color: rgba(212, 48, 48, 1);
                margin-left: auto;
            }
        }
    }

    .addCard {
        border-radius: 10px 10px, 0px, 0px;
        background: rgba(42, 130, 228, 1);
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24.32px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 35.23px;
        color: rgba(255, 255, 255, 1);
        margin-top: auto;
    }
    .dialogContent{
        display: flex;
        justify-content: center;
        padding: 40px 0;
    }
    .cardNamePut{
        border: none;
        border-bottom: 1px solid rgba(166, 166, 166, 1);
        margin: auto;
    }
}
#loading{
    margin:0 auto;
}
</style>
